import { DialogTitle } from '@mui/material';
import { ApplicationError } from '../../../../../Errors';
import { useUpdateTalentTag } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../edit/Context';
import { useTargetTagContext } from '../Contest';
import { TalentTagFormContent, useTalentTagForm } from './TalentTagForm';

export const EditTalentTagDialog = () => {
    const { closeDialog } = useDialogContext();
    const { tag } = useTargetTagContext();
    if (tag === null) {
        throw new ApplicationError('The tag has not been set');
    }
    const form = useTalentTagForm(tag);
    const mutation = useUpdateTalentTag(tag);
    return (
        <>
            <DialogTitle>タグを更新する</DialogTitle>
            <TalentTagFormContent form={form} />
            <DialogActionButtons
                form={form}
                submitLabel="タグを更新する"
                cancelLabel="キャンセル"
                errorMessage="タグの更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
