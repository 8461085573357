import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { sortByKey } from '../../../lib/ArrayUtils';
import { useDebutArticles, usePopularArticles } from '../../../queries/notes';
import { useCurrentTimeContext } from '../../Context';
import { CalendarMonthIcon, DateRangeIcon, EmojiPeopleIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { WaitQuery } from '../../WaitLoading';
import { DebutArticleList } from './DebutArticleList';
import { MonthNav } from './MonthNav';
import { ARTICLES_PER_PAGE, PopularArticleList } from './PopularArticleList';

const WEEKLY_PAGE_LIMIT = 1;
const MONTHLY_PAGE_LIMIT = 10;

export const Trend = () => {
    const { currentTime } = useCurrentTimeContext();
    const now = dayjs(currentTime);
    const maybeWeeklyPopularArticles = usePopularArticles(
        now.startOf('isoWeek').toDate(),
        now.toDate(),
        ARTICLES_PER_PAGE * WEEKLY_PAGE_LIMIT
    );
    const maybeMonthlyPopularArticles = usePopularArticles(
        now.startOf('month').toDate(),
        now.toDate(),
        ARTICLES_PER_PAGE * MONTHLY_PAGE_LIMIT
    );
    const maybeDebutArticles = useDebutArticles(now.startOf('month').toDate(), now.toDate());
    return (
        <Box>
            <SubTitle mt={4} mb={2} title="今週の人気記事" icon={DateRangeIcon} />
            <WaitQuery query={maybeWeeklyPopularArticles}>
                {({ data }) => <PopularArticleList articles={data.articles} />}
            </WaitQuery>
            <SubTitle
                mt={4}
                mb={2}
                title="今月のはじめまして（初投稿記事）"
                icon={EmojiPeopleIcon}
            />
            <WaitQuery query={maybeDebutArticles}>
                {({ data }) => (
                    <DebutArticleList articles={sortByKey(data.articles, 'publishedAt', 'desc')} />
                )}
            </WaitQuery>
            <SubTitle
                mt={4}
                mb={2}
                title={`${now.format('M')}月の人気記事`}
                icon={CalendarMonthIcon}
            />
            <WaitQuery query={maybeMonthlyPopularArticles}>
                {({ data }) => <PopularArticleList articles={data.articles} />}
            </WaitQuery>
            <Box mt={2}>
                <MonthNav current={currentTime} />
            </Box>
        </Box>
    );
};
