import { Box, Card, CardContent, Typography } from '@mui/material';
import type { NotesArticleSummary } from '@spec/Notes';
import dayjs from 'dayjs';
import { getCorporate } from '../../../domains/Organization';
import { fullName } from '../../../domains/Talent';
import { CardLink } from '../../ActionButtons';
import { useTeamsContext } from '../../Context';
import { AccessTimeIcon, PushPinIcon } from '../../Icons';
import { IconText } from '../../IconText';
import { NoItems } from '../../NoItems';
import { RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { ArticleTags } from '../ArticleTags';
import { useAuthor } from '../Hooks';
import { LikesCounter } from '../LikesCounter';
import { getArticleUrl } from '../urls';

export const DebutArticleList = (props: { articles: NotesArticleSummary[] }) => {
    if (props.articles.length === 0) {
        return <NoItems>今月の初投稿はまだありません</NoItems>;
    }
    return (
        <Box>
            <Box mt={1} display="flex" flexWrap="wrap" rowGap={1} columnGap={2}>
                {props.articles.map((v) => (
                    <DebutArticleCard key={v.id} article={v} />
                ))}
            </Box>
        </Box>
    );
};

export const DebutArticleCard = (props: { article: NotesArticleSummary }) => {
    const { teams } = useTeamsContext();
    const v = props.article;
    const author = useAuthor(v);
    const corporate = getCorporate(author, teams);
    const articleUrl = getArticleUrl(v.id, author.hitonowaId);
    return (
        <Card
            square={false}
            sx={(theme) => ({
                flexGrow: 1,
                // Ensure consistent sizing and prevent stretching
                flexBasis: `calc(33.3333% - ${theme.spacing(2 * 2)} / 3)`,
                maxWidth: `calc(33.3333% - ${theme.spacing(2 * 2)} / 3)`,
            })}
        >
            <CardLink to={articleUrl} sx={{ height: '100%' }}>
                <CardContent>
                    <Box display="flex">
                        <TalentAvatar size="mediumLarge" talent={author} />
                        <Box ml={1} flexGrow={1}>
                            <RouterLink display="inline-block" variant="h6" to={articleUrl} modal>
                                {v.title}
                                {v.isPinned === true && (
                                    <PushPinIcon fontSize="small" color="secondary" />
                                )}
                            </RouterLink>
                            <Box mt={0.5} display="flex" rowGap={0} columnGap={1}>
                                <Box flexGrow={1}>
                                    <Typography variant="body2">
                                        {fullName(author)}
                                        {author.nicknames !== '' && `（${author.nicknames}）`}
                                    </Typography>
                                    <Typography variant="body2">{corporate.name}</Typography>
                                </Box>
                                <LikesCounter article={props.article} />
                            </Box>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                        </Box>
                    </Box>
                    <ArticleTags tags={v.tags} />
                </CardContent>
            </CardLink>
        </Card>
    );
};
