import dayjs, { type Dayjs } from 'dayjs';
import { useCurrentTimeContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { BeforeIcon, NextIcon } from '../../Icons';
import { RouterLink } from '../../RouterLink';
import { getTrendArchiveUrl } from '../urls';

export const MonthNav = (props: { current: Date }) => {
    const { currentTime } = useCurrentTimeContext();
    const now = dayjs(currentTime);
    const current = dayjs(props.current);
    const [lastYear, lastMonth] = getYearMonth(current.subtract(1, 'month'));
    const next = current.add(1, 'month');
    const [nextYear, nextMonth] = getYearMonth(next);
    return (
        <FlexBox justifyContent="space-between">
            <RouterLink to={getTrendArchiveUrl(lastYear, lastMonth)}>
                <FlexBox>
                    <BeforeIcon />
                    {lastYear}年{lastMonth}月
                </FlexBox>
            </RouterLink>
            {now > next && (
                <RouterLink to={getTrendArchiveUrl(nextYear, nextMonth)}>
                    <FlexBox>
                        {nextYear}年{nextMonth}月
                        <NextIcon />
                    </FlexBox>
                </RouterLink>
            )}
        </FlexBox>
    );
};

const getYearMonth = (d: Dayjs): [number, number] => [d.year(), d.month() + 1];
