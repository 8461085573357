import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import type { NotesArticleSummary } from '@spec/Notes';
import { useState } from 'react';
import { ActionContainer } from '../../ActionButtons';
import { FeedIcon } from '../../Icons';
import { NoItems } from '../../NoItems';
import { ArticleCard } from './ArticleCard';

export const ARTICLES_PER_PAGE = 9;

export const PopularArticleList = (props: { articles: NotesArticleSummary[] }) => {
    const [page, setPage] = useState(1);
    if (props.articles.length === 0) {
        return <NoItems>いいね！された記事はまだありません</NoItems>;
    }
    const slicedArticles = props.articles.slice(0, ARTICLES_PER_PAGE * page);
    return (
        <Box>
            <Box mt={1} display="flex" flexWrap="wrap" rowGap={1} columnGap={2}>
                {slicedArticles.map((v, i) => (
                    <ArticleCard key={v.id} article={v} rank={i + 1} />
                ))}
            </Box>
            {props.articles.length > slicedArticles.length && (
                <ActionContainer mt={2}>
                    <LoadingButton
                        fullWidth
                        variant="outlined"
                        size="medium"
                        loadingPosition="start"
                        startIcon={<FeedIcon />}
                        onClick={() => setPage((current) => current + 1)}
                    >
                        もっと見る
                    </LoadingButton>
                </ActionContainer>
            )}
        </Box>
    );
};
