import { DialogContent } from '@mui/material';
import type { TalentTag, TalentTagRequest } from '@spec/Talent';
import { FormTextField, useForm, useFormText, type Form } from '../../../../../lib/Form';
import { maxLength, required } from '../../../../../lib/Form/Validators';
import { DialogRow } from '../../../../StableDialog';

type TalentTagForm = Form<TalentTagRequest>;

export const useTalentTagForm = (tag: TalentTag | null) => {
    return useForm<TalentTagRequest>({
        category: useFormText(tag?.category ?? '', [required, maxLength(191)]),
        term: useFormText(tag?.term ?? '', [required, maxLength(191)]),
        label: useFormText(tag?.label ?? '', [required, maxLength(191)]),
    });
};

export const TalentTagFormContent = (props: { form: TalentTagForm }) => {
    return (
        <DialogContent>
            <DialogRow label="カテゴリ">
                <FormTextField autoFocus fullWidth formText={props.form.category} />
            </DialogRow>
            <DialogRow label="期間">
                <FormTextField fullWidth formText={props.form.term} />
            </DialogRow>
            <DialogRow label="詳細">
                <FormTextField fullWidth formText={props.form.label} />
            </DialogRow>
        </DialogContent>
    );
};
