import type { TalentId } from './Talent';

export type ArticleSummaryBase = {
    id: number;
    talentId: TalentId;
    title: string;
    publishedAt: Date;
    isPinned: boolean;
    tags: string[];
    likes: number;
};

export type IsLiked = { isLiked: boolean };

export type ArticleBase = ArticleSummaryBase & { content: string };

export type NotesArticle = ArticleBase & IsLiked;

export type NotesArticleSummary = ArticleSummaryBase & IsLiked;

/**
 * articles: all articles without content
 * nextId: the id of the next article
 */
export type NotesArticleSummariesResponse = {
    articles: Array<NotesArticleSummary>;
    nextOffset: number | null;
    nextId?: number | null; // for backward compatibility
};
/**
 * articles: all articles without content
 * nextOffset: the timestamp of the liked at for the next article
 */
export type NotesLikedArticlesResponse = {
    articles: Array<NotesArticleSummary>;
    nextOffset: number | null;
};
/**
 * articles: all articles without content
 */
export type NotesAuthorArticleSummariesResponse = {
    articles: Array<NotesArticleSummary>;
};
/**
 * articles: all articles without content
 */
export type NotesPinnedArticleSummariesResponse = {
    articles: Array<NotesArticleSummary>;
};

export type NotesPopularArticlesResponse = {
    articles: NotesArticleSummary[];
};

export type NotesDebutArticlesResponse = {
    articles: NotesArticleSummary[];
};

export type NotesArticleResponse = {
    article: NotesArticle;
    likedTalentIds: TalentId[];
    comments: NotesComment[];
};

export type PostNotesArticleRequest = {
    title: string;
    content: string;
    isPinned?: boolean;
    tags: string[];
};
export type PostNotesArticleResponse = { articleId: number };

export type NotesLikesResponse = {
    likes: Record<number, number>;
    likedArticleIds: number[];
};

/**
 * Upload image request
 * image: base64 encoded image
 */
export type UploadImageRequest = { image: string };

/**
 * Upload image response
 * path: Firebase storage path
 */
export type UploadImageResponse = { path: string };

export const NOTES_IMAGE_MAX_WIDTH = 1000;

export type NotesTagsResponse = { tags: string[] };

export type NotesNotificationType = 'like' | 'comment';

type NotesNotificationBase<T extends NotesNotificationType> = {
    type: T;
    articleId: number;
    talentId: TalentId;
    createdAt: Date;
    unread: boolean;
};

export type NotesLikeNotification = NotesNotificationBase<'like'>;
export type NotesCommentNotification = NotesNotificationBase<'comment'>;

export type NotesNotification = NotesLikeNotification | NotesCommentNotification;

export type NotesNotificationsResponse = { notifications: NotesNotification[] };

export type NotesNotificationsReadRequest = { readAt: Date };

export type NotesComment = {
    id: number;
    articleId: number;
    talentId: TalentId;
    content: string;
    commentedAt: Date;
};

export type PostNotesCommentRequest = { content: string };

export const NOTES_COMMENT_MAX_LENGTH = 2000;
