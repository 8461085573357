import { Box, Breadcrumbs, Typography, useTheme } from '@mui/material';
import type { Team } from '@spec/Organization';
import { TeamId } from '@spec/Organization';
import type { Talent } from '@spec/Talent';
import { getTeamUrl } from '../../../../Routes';
import { generateBreadcrumbs } from '../../../../domains/Organization';
import { fullNameKana, gradeToString } from '../../../../domains/Talent';
import { FlexBox } from '../../../FlexBox';
import { IconText } from '../../../IconText';
import { AccountBoxIcon, PhoneIcon, TodayIcon } from '../../../Icons';
import { ExternalLink, RouterLink } from '../../../RouterLink';
import { GitHubId } from '../../GitHubId';
import { HitonowaId } from '../../HitonowaId';
import { SlackChannel } from '../../SlackChannel';
import { SlackName } from '../../SlackName';
import { CopyableEmail } from '../CopyableEmail';
import { CurrentCircle } from '../CurrentCircle';
import { SecondmentRow } from '../SecondmentRow';
import { TalentTags } from '../tags/TalentTag';

export const Carta = (props: { talent: Talent; teams: Team[] }) => {
    const theme = useTheme();
    const t = props.talent;
    return (
        <Box>
            <Box>
                <FlexBox mt={0.5} gap={theme.spacing(2)}>
                    <Typography variant="body2">{fullNameKana(t)}</Typography>
                    <Typography variant="body2">{t.romanName}</Typography>
                    <HitonowaId hitonowaId={t.hitonowaId} enableLink />
                    <GitHubId githubId={t.githubId} />
                </FlexBox>
                <FlexBox gap={theme.spacing(1)}>
                    <CopyableEmail email={t.employment.email} />
                    {t.emailAlias !== '' && <CopyableEmail email={t.emailAlias} />}
                    <SlackName talent={t} enableLink />
                    <SlackChannel talent={t} />
                    <IconText icon={TodayIcon}>
                        <ExternalLink
                            variant="body2"
                            href={`https://calendar.google.com/calendar/u/0/embed?src=${t.employment.email}`}
                        >
                            Googleカレンダー
                        </ExternalLink>
                    </IconText>
                    {t.selfIntroductionUrl && (
                        <IconText icon={AccountBoxIcon}>
                            <ExternalLink variant="body2" href={t.selfIntroductionUrl}>
                                自己紹介
                            </ExternalLink>
                        </IconText>
                    )}
                    {t.tel !== '' && <IconText icon={PhoneIcon}>{t.tel}</IconText>}
                </FlexBox>
            </Box>
            <FlexBox gap={theme.spacing(2)}>
                <Typography variant="body2">社員番号 : {t.employment.employeeCode}</Typography>
                {t.position !== '' && <Typography variant="body2">役職 : {t.position}</Typography>}
                <Typography variant="body2">雇用形態 : {t.employment.employmentStatus}</Typography>
                <Typography variant="body2">グレード : {gradeToString(t.grade)}</Typography>
                <Typography variant="body2">勤務地 : {t.workplace ?? '-'}</Typography>
                <CurrentCircle talent={t} />
            </FlexBox>
            <FlexBox mt={0.5} alignItems="baseline">
                <Box mr={0.5}>
                    <Typography variant="body2">所属 :</Typography>
                </Box>
                <TeamBreadCrumbs teamId={t.teamId} teams={props.teams} position="" />
            </FlexBox>
            {t.additionalPosts.length > 0 && (
                <FlexBox alignItems="baseline">
                    <Box mr={0.5}>
                        <Typography variant="body2">兼務 :</Typography>
                    </Box>
                    <Box>
                        {t.additionalPosts.map((v) => (
                            <TeamBreadCrumbs
                                key={v.teamId}
                                teamId={v.teamId}
                                teams={props.teams}
                                position={v.position}
                            />
                        ))}
                    </Box>
                </FlexBox>
            )}
            {t.secondment !== null && <SecondmentRow secondment={t.secondment} />}
            <TalentTags talent={t} />
        </Box>
    );
};

const TeamBreadCrumbs = (props: { teamId: TeamId; teams: Team[]; position: string }) => {
    const teamNav = generateBreadcrumbs(props.teamId, props.teams);
    const slicedTeamNav = teamNav.length > 1 ? teamNav.slice(1) : teamNav;
    return (
        <FlexBox>
            <Breadcrumbs>
                {slicedTeamNav.map((v) => (
                    <RouterLink key={v.id} to={getTeamUrl(v.id)} variant="body2">
                        {v.name}
                    </RouterLink>
                ))}
            </Breadcrumbs>
            {props.position !== '' && <Typography variant="body2">（{props.position}）</Typography>}
        </FlexBox>
    );
};
