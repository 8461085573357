import Looks3Icon from '@mui/icons-material/Looks3';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { Box, Card, CardContent, Typography } from '@mui/material';
import type { NotesArticleSummary } from '@spec/Notes';
import dayjs from 'dayjs';
import { fullName } from '../../../domains/Talent';
import { CardLink } from '../../ActionButtons';
import { AccessTimeIcon, PushPinIcon } from '../../Icons';
import { IconText } from '../../IconText';
import { RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { ArticleTags } from '../ArticleTags';
import { useAuthor } from '../Hooks';
import { LikesCounter } from '../LikesCounter';
import { getArticleUrl } from '../urls';

export const ArticleCard: React.FC<{ article: NotesArticleSummary; rank: number }> = (props) => {
    const v = props.article;
    const author = useAuthor(v);
    const articleUrl = getArticleUrl(v.id, author.hitonowaId);
    return (
        <Card
            square={false}
            sx={(theme) => ({
                flexGrow: 1,
                // Ensure consistent sizing and prevent stretching
                flexBasis: `calc(33.3333% - ${theme.spacing(2 * 2)} / 3)`,
                maxWidth: `calc(33.3333% - ${theme.spacing(2 * 2)} / 3)`,
            })}
        >
            <CardLink to={articleUrl} sx={{ height: '100%' }}>
                <CardContent sx={{ position: 'relative' }}>
                    <RankIcon rank={props.rank} />
                    <RouterLink display="inline-block" variant="h6" to={articleUrl} modal>
                        {v.title}
                        {v.isPinned === true && <PushPinIcon fontSize="small" color="secondary" />}
                    </RouterLink>
                    <Box mt={1} display="flex" rowGap={0} columnGap={1}>
                        <TalentAvatar size="medium" talent={author} />
                        <Box flexGrow={1}>
                            <Typography variant="body2">
                                {fullName(author)}
                                {author.nicknames !== '' && `（${author.nicknames}）`}
                            </Typography>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                        </Box>
                        <LikesCounter article={props.article} />
                    </Box>
                    <ArticleTags tags={v.tags} />
                </CardContent>
            </CardLink>
        </Card>
    );
};

const RankIcon = (props: { rank: number }) => {
    if (props.rank > 3) {
        return null;
    }
    return (
        <Box sx={{ position: 'absolute', top: -3, right: -3 }}>
            {props.rank === 1 && <LooksOneIcon sx={{ color: '#F8D000' }} />}
            {props.rank === 2 && <LooksTwoIcon sx={{ color: '#C0C0C0' }} />}
            {props.rank === 3 && <Looks3Icon sx={{ color: '#CD7F32' }} />}
        </Box>
    );
};
