import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { ApplicationError } from '../../../Errors';
import { sortByKey } from '../../../lib/ArrayUtils';
import { useDebutArticles, usePopularArticles } from '../../../queries/notes';
import { CalendarMonthIcon, EmojiPeopleIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { WaitQuery } from '../../WaitLoading';
import { DebutArticleList } from './DebutArticleList';
import { MonthNav } from './MonthNav';
import { ARTICLES_PER_PAGE, PopularArticleList } from './PopularArticleList';

const MONTHLY_PAGE_LIMIT = 10;

export const TrendArchive = () => {
    const { year, month } = useParams();
    const from = dayjs(`${year}-${month}-1`);
    if (!from.isValid()) {
        throw new ApplicationError(`${from} is invalid year/month`);
    }
    const to = from.endOf('month');
    const maybeMonthlyPopularArticles = usePopularArticles(
        from.toDate(),
        to.toDate(),
        ARTICLES_PER_PAGE * MONTHLY_PAGE_LIMIT
    );
    const maybeDebutArticles = useDebutArticles(from.toDate(), to.toDate());
    return (
        <Box>
            <SubTitle
                mt={4}
                mb={2}
                title={`${from.format('YYYY年M月')}のはじめまして（初投稿記事）`}
                icon={EmojiPeopleIcon}
            />
            <WaitQuery query={maybeDebutArticles}>
                {({ data }) => (
                    <DebutArticleList articles={sortByKey(data.articles, 'publishedAt', 'desc')} />
                )}
            </WaitQuery>
            <SubTitle
                mt={4}
                mb={2}
                title={`${from.format('YYYY年M月')}の人気記事`}
                icon={CalendarMonthIcon}
            />
            <WaitQuery query={maybeMonthlyPopularArticles}>
                {({ data }) => <PopularArticleList articles={data.articles} />}
            </WaitQuery>
            <Box mt={2}>
                <MonthNav current={from.toDate()} />
            </Box>
        </Box>
    );
};
