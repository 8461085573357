import type { Talent, TalentTag } from '@spec/Talent';
import { createContext, useContext, useState, type ReactNode } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode = 'AddTalentTag' | 'EditTalentTag' | 'DeleteTalentTag';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const TargetTalentContext = createContext({} as { talent: Talent });
const TargetTalentContextProvider = (props: { talent: Talent; children: ReactNode }) => {
    return (
        <TargetTalentContext.Provider value={{ talent: props.talent }}>
            {props.children}
        </TargetTalentContext.Provider>
    );
};
export const useTargetTalentContext = () => useContext(TargetTalentContext);

const TargetTagContext = createContext(
    {} as { tag: TalentTag | null; setTag: (v: TalentTag | null) => void }
);
const TargetTagContextProvider = (props: { children: ReactNode }) => {
    const [tag, setTag] = useState<TalentTag | null>(null);
    return (
        <TargetTagContext.Provider value={{ tag, setTag }}>
            {props.children}
        </TargetTagContext.Provider>
    );
};
export const useTargetTagContext = () => useContext(TargetTagContext);

export const ContextProvider = (props: { talent: Talent; children: ReactNode }) => {
    return (
        <DialogContextProvider>
            <TargetTalentContextProvider talent={props.talent}>
                <TargetTagContextProvider>{props.children}</TargetTagContextProvider>
            </TargetTalentContextProvider>
        </DialogContextProvider>
    );
};
