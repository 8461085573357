import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { ApplicationError } from '../../../../../Errors';
import { useEmptyForm } from '../../../../../lib/Form';
import { useDeleteTalentTag } from '../../../../../queries/talent';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext } from '../../edit/Context';
import { useTargetTagContext } from '../Contest';

export const DeleteTalentTagDialog = () => {
    const { closeDialog } = useDialogContext();
    const { tag } = useTargetTagContext();
    if (tag === null) {
        throw new ApplicationError('The tag has not been set');
    }
    const form = useEmptyForm();
    const mutation = useDeleteTalentTag(tag);
    return (
        <>
            <DialogTitle>タグを削除する</DialogTitle>
            <DialogContent>
                <DialogRow label="カテゴリ">
                    <Typography>{tag.category}</Typography>
                </DialogRow>
                <DialogRow label="期間">
                    <Typography>{tag.term}</Typography>
                </DialogRow>
                <DialogRow label="詳細">
                    <Typography>{tag.label}</Typography>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="タグを削除する"
                cancelLabel="キャンセル"
                errorMessage="タグの削除に失敗しました"
                onSubmit={() => mutation.mutateAsync()}
                closeDialog={closeDialog}
            />
        </>
    );
};
