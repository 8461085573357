import { DialogTitle } from '@mui/material';
import { useAddTalentTag } from '../../../../../queries/talent';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../edit/Context';
import { useTargetTalentContext } from '../Contest';
import { TalentTagFormContent, useTalentTagForm } from './TalentTagForm';

export const AddTalentTagDialog = () => {
    const { closeDialog } = useDialogContext();
    const { talent } = useTargetTalentContext();
    const form = useTalentTagForm(null);
    const mutation = useAddTalentTag(talent.employment.employeeCode);
    return (
        <>
            <DialogTitle>タグを付ける</DialogTitle>
            <TalentTagFormContent form={form} />
            <DialogActionButtons
                form={form}
                submitLabel="タグを付ける"
                cancelLabel="キャンセル"
                errorMessage="タグ付けに失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
